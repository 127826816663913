
import { Component, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import moment from "moment";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import FacilityService from "@/services/facilities";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import Editor from "@tinymce/tinymce-vue";
import { uploadToS3 } from "@/plugins/s3";
import { CreateAnnouncement, AnnouncementData } from "@/@types/management";

@Component({
  name: "HospitalAnnouncementSetting",
  components: {
    Header,
    Table,
    ManagementFooter,
    Button,
    FormSet,
    Editor,
  },
})
export default class HospitalAnnouncementSetting extends CheckAdminLogin {
  private filePickerCallback(callback: any, value: any, meta: any) {
    if (meta.filetype === "image") {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.onchange = function () {
        if (!input.files) {
          return;
        }
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
          callback(e.target?.result, {
            alt: file.name,
          });
        };
        reader.readAsDataURL(file);
      };
      input.click();
    }
  }
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private get me() {
    return AuthModule.me;
  }
  private isUploading = false;
  private tableLoading = false;
  private announcementSettingHeaders = [
    { text: "お知らせタイトル", value: "title", width: "550px" },
    { text: "公開開始日", value: "publish_start_at" },
    { text: "公開終了日", value: "publish_end_at" },
    { text: "最終更新日時", value: "updated_at" },
    { text: "", value: "actions", sortable: false, width: "100px" },
  ];
  private showLabel = false;
  private announcementList: AnnouncementData[] = [];
  private file: any = null;
  private isTinyEmpty = false;
  private tinyMceApiKey = process.env.VUE_APP_TINYMCE_API_KEY;

  private async uploadFile() {
    if (!this.file) {
      return;
    }
    if (this.file.type !== "application/pdf") {
      this.showModal("fileTypeError");
      return;
    }
    this.isUploading = true;
    try {
      const res: any = await uploadToS3(this.file);
      this.form.pdf_s3_url = `s3://${process.env.VUE_APP_S3_BUCKET_NAME}/${res.Key}`;
      this.isUploading = false;
      return true;
    } catch (error: any) {
      this.showModal("saveError");
      this.isUploading = false;

      throw new Error(error);
    }
  }
  private form: CreateAnnouncement = {
    title: "",
    publish_start_at: "",
    publish_end_at: "",
    pdf_s3_url: "",
    body: "",
    body_type: "TEXT",
  };

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async mounted() {
    this.tableLoading = true;
    await this.getAnnouncementList();
    this.tableLoading = false;
  }

  private get dateTimeCheck() {
    const start = moment(this.form.publish_start_at);
    const end = moment(this.form.publish_end_at);
    return end.isAfter(start);
  }

  private displayDateFactory(date: string, add9?: boolean) {
    if (!add9) {
      return moment(date).format("YYYY/MM/DD");
    } else {
      return moment(date).format("YYYY/MM/DD HH:mm");
    }
  }

  private resetForm() {
    this.form = {
      title: "",
      publish_start_at: "",
      publish_end_at: "",
      pdf_s3_url: "",
      body: "",
      body_type: "TEXT",
    };
    setTimeout(() => {
      this.isTinyEmpty = false;
    }, 100);
    (this.$refs.observer as any).reset();
  }

  private async getAnnouncementList() {
    const res = await FacilityService.getAnnouncements();
    this.announcementList = res.data.data;
    // 降順にソート
    this.announcementList.sort(function (x, y) {
      var firstDate = new Date(x.publish_start_at),
        SecondDate = new Date(y.publish_start_at);
      if (firstDate < SecondDate) return 1;
      if (firstDate > SecondDate) return -1;
      return 0;
    });
  }

  private editAnnouncement(id: string) {
    this.$router.push({ name: "HospitalAnnouncementSettingEdit", params: { id } });
  }

  @Watch("form.body")
  private onBodyChange() {
    if (this.form.body_type === "TEXT") {
      this.isTinyEmpty = this.form.body === "";
    }
  }

  private async createAnnouncement() {
    if (this.form.body_type === "TEXT") {
      this.isTinyEmpty = this.form.body === "";
    }
    if (!(await (this.$refs.observer as any).validate())) {
      return;
    }
    if (this.form.body_type === "TEXT" && this.isTinyEmpty) {
      return;
    }
    try {
      this.isUploading = true;
      if (this.form.body_type === "PDF") {
        const success = await this.uploadFile();
        if (!success) {
          this.isUploading = false;
          return;
        }
      }
      this.form.publish_start_at = moment(this.form.publish_start_at)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .format();
      if (this.form.publish_end_at) {
        this.form.publish_end_at = moment(this.form.publish_end_at)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format();
        if (!this.dateTimeCheck) {
          this.showLabel = true;
          setTimeout(() => {
            this.showLabel = false;
          }, 5000);
          this.isUploading = false;
          return;
        }
      } else {
        delete this.form.publish_end_at;
      }
      // this.formの中身で空文字、null、undefinedのものを削除
      (Object.keys(this.form) as Array<keyof CreateAnnouncement>).forEach((key) => {
        if (this.form[key] === "" || this.form[key] === null || this.form[key] === undefined) {
          delete this.form[key];
        }
      });
      // TEXTの時はs3_urlを削除、PDFの時はbodyを削除
      if (this.form.body_type === "TEXT") {
        delete this.form.pdf_s3_url;
      } else {
        delete this.form.body;
      }
      await FacilityService.createAnnouncement(this.form);
      this.resetForm();
      window.location.reload();
    } catch (error: any) {
      if (!error.response) {
        this.showModal("fileSizeError");
      } else {
        this.showModal("saveError");
      }
      this.isUploading = false;
      throw new Error(error);
    }
  }

  private async linkToAnnouncementDetail(item: AnnouncementData) {
    FacilityModule.changeHospitalName("お知らせ表示確認用病院");
    if (process.env.NODE_ENV === "development") {
      FacilityModule.changeHospitalId("d14dd9a0-eee6-45df-a832-4dfe7349f538");
    }
    // pdfの場合は別タブで開く
    if (item.body_type === "PDF") {
      const key = item.pdf_s3_url?.split(`${process.env.VUE_APP_S3_BUCKET_NAME}/`).pop();
      if (!key) return;
      window.open(process.env.VUE_APP_INFO_URL + "/" + key, "_blank");
      return;
    }
    window.open(`/announcements/${item.id}`, "_blank");
  }
}
